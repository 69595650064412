// atenção: na próxima vez que precisarmos usar HTML perigoso, lembremos de sanitizá-lo para aplicar a sanitização nesses dangerouslySetInnerHTML também
import axios from 'axios';
import React, { Component } from 'react';
import validator from 'validator';
import SweetAlert from 'react-bootstrap-sweetalert/lib/dist/SweetAlert';
import intl from 'react-intl-universal';
import MaskedInput from 'react-text-mask';
import { Button, Input } from 'reactstrap';
import imgfLogo from '../../assets/img/logo.png';
import { Alert } from '../../components/Alert';
import { InputPassword } from '../../components/InputPassword';
import { signIn } from '../../config/Authentication';
import { extractErrorMessages } from '../../config/Axios';
import { validarCNPJ, validarCPF } from '../../config/Validator';
import DatabaseBeingCreated from './DatabaseBeingCreated';
import PageClean from '../default-v2/PageClean';
import './styles/register.scss';

const oLocales = {
  en: require('../../locales/en.json'),
  'pt-br': require('../../locales/pt-br.json')
};

class Register extends Component {
  constructor(oProps) {
    super(oProps);
    document.body.classList.add('w-bkg', 'externalRegister');
    this.state = {
      bDatabaseStart: false,
      bIsSend: false,
      bLoad: true,
      bLoadingPostalCode: false,
      oUser: {},
      oCustomer: {
        cust_ctype: 'PJ',
        adct_nid: null
      },
      cToken: oProps.match.params.cToken,
      bValidPassword: false,
      oTerm: null
    };
  }

  componentDidMount = () => {
    const { cToken } = this.state;
    axios
      .get(`/customer/register/${cToken}`)
      .then(oResponse => {
        this.setState({
          oUser: oResponse.data.oUser,
          oTerm: oResponse.data.oTerm,
          bLoad: false
        });
      })
      .catch(oError => {
        this.setError(oError);
      });
  };

  validatePassword = evtPassword => {
    if (
      validator.isStrongPassword(evtPassword.target.value, {
        minLength: 6,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1
      })
    ) {
      this.setState({
        bValidPassword: true
      });
    } else {
      this.setState({
        bValidPassword: false
      });
    }
  };

  inputChangeHandlerUser = evtChange => {
    const { oUser } = this.state;

    oUser[evtChange.target.name] = evtChange.target.value;
    this.setState({
      oUser
    });
  };

  inputChangeHandlerCustomer = evtChange => {
    const { oCustomer } = this.state;

    if (evtChange.target.name === 'cust_clanguage') {
      intl.init({
        currentLocale: evtChange.target.value,
        locales: oLocales
      });
    }

    oCustomer[evtChange.target.name] = evtChange.target.type === 'checkbox' ? evtChange.target.checked : evtChange.target.value;
    this.setState({
      oCustomer
    });
  };

  openTerm = evtClick => {
    evtClick.preventDefault();

    const { oTerm } = this.state;

    axios
      .get(`/adhesioncontract/${oTerm.adct_nid}`)
      .then(oResponse => {
        this.setState({
          rcmpAlert: (
            <SweetAlert
              onConfirm={() => {
                this.hideAlert();
              }}
              title={oResponse.data.adct_ctitle}
            >
              <div
                style={{ maxHeight: '300px', padding: '15px', overflowX: 'hidden', overflowY: 'scroll' }}
                dangerouslySetInnerHTML={{ __html: oResponse.data.adct_ctext }}
              />
            </SweetAlert>
          )
        });
      })
      .catch(() => {
        this.setError(intl.get('PlanIndex.termo_de_adesao_indisponivel'));
      });
  };

  validateCNPJ = evtKeyPress => {
    if (!validarCNPJ(evtKeyPress.target.value)) {
      const { oCustomer } = this.state;
      oCustomer.cCnpj = '';
      this.setState({
        oCustomer,
        rcmpAlert: (
          <SweetAlert
            danger
            confirmBtnText="Ok"
            confirmBtnBsStyle="danger"
            title={intl.get('Customers.cnpj_invalido')}
            onConfirm={this.hideAlert}
            onCancel={this.hideAlert}
          />
        )
      });
    }
  };

  validateCPF = evtKeyPress => {
    if (!validarCPF(evtKeyPress.target.value)) {
      const { oCustomer } = this.state;
      oCustomer.cust_ccpf = '';
      this.setState({
        oCustomer,
        rcmpAlert: (
          <SweetAlert
            danger
            confirmBtnText="Ok"
            confirmBtnBsStyle="danger"
            title={intl.get('Customers.cpf_invalido')}
            onConfirm={this.hideAlert}
            onCancel={this.hideAlert}
          />
        )
      });
    }
  };

  verifyCEP = () => {
    const { oCustomer } = this.state;

    this.setState({
      bLoadingPostalCode: true
    });

    const cValueCEP = document.getElementById('cust_cpostalcode').value.replace('-', '');
    oCustomer.cust_cibgecode = '';
    axios
      .get(`https://cep.acredite.se/cep/${cValueCEP}/json`)
      .then(oResponse => {
        Object.keys(oResponse.data.result).map(cItem => {
          switch (cItem) {
            case 'logradouro':
              oCustomer.cust_cstreet = oResponse.data.result[cItem];
              break;
            case 'localidade':
              oCustomer.cust_ccity = oResponse.data.result[cItem];
              break;
            case 'estado':
              oCustomer.cust_cstate = oResponse.data.result[cItem];
              break;
            case 'siglaEstado':
              oCustomer.cust_cinitialsState = oResponse.data.result[cItem];
              break;
            case 'bairro':
              oCustomer.cust_cdistrict = oResponse.data.result[cItem];
              break;
            case 'cibgelocalidade':
              oCustomer.cust_cibgecode = oResponse.data.result[cItem];
              break;
            default:
              break;
          }
          oCustomer.cust_ccountry = 'Brasil';
          this.setState({ oCustomer, bLoadingPostalCode: false });
          return oCustomer;
        });
      })
      .catch(() => {
        this.setState({
          bLoadingPostalCode: false,
          rcmpAlert: (
            <SweetAlert
              danger
              confirmBtnText="Ok"
              confirmBtnBsStyle="danger"
              title={intl.get('Register.cep_nao_encontrado')}
              onConfirm={this.hideAlert}
              onCancel={this.hideAlert}
            />
          )
        });
      });
  };

  handleSubmit = evtSubmit => {
    const { cToken, oCustomer, oUser, bValidPassword, oTerm } = this.state;
    evtSubmit.preventDefault();

    if (oCustomer.cust_bterm === false) {
      this.setState({
        rcmpAlertFlash: (
          <Alert type="error" key="term" isOpen onCloseAlert={() => { }}>
            {intl.get('Register.erro_aceitar_termo')}
          </Alert>
        )
      });
      window.scrollTo(0, 0);
    } else {
      oCustomer.adct_nid = oTerm.adct_nid;
      if (!oCustomer.cust_cname) {
        oCustomer.cust_cname = oUser.user_cname;
      }

      if (oUser.user_cpassword !== oUser.user_cpasswordconfirm) {
        this.setState({
          rcmpAlertFlash: (
            <Alert type="error" key="password1" isOpen onCloseAlert={() => { }}>
              {intl.get('Customers.erro_confirmacao_senha')}
            </Alert>
          )
        });
        window.scrollTo(0, 0);
      } else if (oUser.user_cpassword.length < 6) {
        this.setState({
          rcmpAlertFlash: (
            <Alert type="error" key="password1" isOpen onCloseAlert={() => { }}>
              {intl.get('User.mensagem_senha_minimo')}
            </Alert>
          )
        });
        window.scrollTo(0, 0);
      } else if (bValidPassword !== true) {
        this.setState({
          rcmpAlertFlash: (
            <Alert type="error" key="password2" isOpen onCloseAlert={() => { }}>
              {intl.get('Customers.password_strength_error')}
            </Alert>
          )
        });
      } else {
        this.setState({
          bIsSend: true
        });

        axios
          .post('/customer', { cToken, oCustomer, oUser })
          .then(oResponse => {
            this.setState({ bDatabaseStart: true, bIsSend: false });
            axios
              .get(`/customer/create-database/${oResponse.data.oCustomer.cust_nid}`)
              .then(oRes => {
                signIn(oRes.data, true);
                window.location = '/';
              })
              .catch(oError => {
                if (oError.message.search("Network Error") !== -1) {
                  window.location.href = "/";
                }
                this.setError(oError);
              });
          })
          .catch(oError => {
            this.setError(oError);
            this.setState({ bIsSend: false });
          });
      }
    }
  };

  hideAlert = () => {
    this.setState({
      rcmpAlert: null
    });
  };

  setError = oError => {
    const aErrorMessages = extractErrorMessages(oError);

    this.setState({
      rcmpAlertFlash: aErrorMessages.map(cMsg => (
        <Alert type="error" key={cMsg} isOpen onCloseAlert={() => { }}>
          <div dangerouslySetInnerHTML={{ __html: cMsg }} />
        </Alert>
      ))
    });
    window.scrollTo(0, 0);
  };

  renderDataWithPagination = () => {
    const { oCustomer, oUser } = this.state;
    return (
      <>
        <fieldset>
          <legend>{intl.get('informacoes')}</legend>

          <label className="split">
            {intl.get('Customers.tipo_cadastro')}
            <Input
              type="select"
              name="cust_ctype"
              id="cust_ctype"
              onChange={this.inputChangeHandlerCustomer}
              value={oCustomer.cust_ctype || ''}
            >
              <option value="PF">{intl.get('Customers.pessoa_fisica')}</option>
              <option value="PJ">{intl.get('Customers.pessoa_juridica')}</option>
            </Input>
          </label>

          {oCustomer.cust_ctype === 'PJ' ? (
            <>
              <label className="split2">
                <span>{intl.get('Customers.cnomefantasia')}</span> <span className="required"> *</span>
                <Input
                  required
                  onChange={this.inputChangeHandlerCustomer}
                  value={oCustomer.cust_cfantasyname || ''}
                  type="text"
                  name="cust_cfantasyname"
                  id="cust_cfantasyname"
                  placeholder={intl.get('Customers.cnomefantasia')}
                />
              </label>

              <label className="split2">
                {intl.get('Customers.ccnpj')} <span className="required"> *</span>
                <MaskedInput
                  onBlur={this.validateCNPJ}
                  // disabled={cliente.ccnpj ? true : false}
                  mask={[
                    /\d/,
                    /\d/,
                    '.',
                    /\d/,
                    /\d/,
                    /\d/,
                    '.',
                    /\d/,
                    /\d/,
                    /\d/,
                    '/',
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    '-',
                    /\d/,
                    /\d/
                  ]}
                  onChange={this.inputChangeHandlerCustomer}
                  render={(cRef, oProps) => (
                    <Input
                      innerRef={cRef}
                      {...oProps} // precisava analisar quais props são usados
                      value={oCustomer.cust_ccnpj || ''}
                      type="text"
                      name="cust_ccnpj"
                      id="cust_ccnpj"
                      placeholder="CNPJ"
                      required={oCustomer.cust_ctype === 'PJ'}
                    />
                  )}
                />
              </label>

              <label className="split2">
                <span>{intl.get('Customers.razao_social')}</span> <span className="required"> *</span>
                <Input
                  required
                  onChange={this.inputChangeHandlerCustomer}
                  value={oCustomer.cust_cname || ''}
                  type="text"
                  name="cust_cname"
                  id="cust_cname"
                  placeholder={
                    oCustomer.cType === 'PJ' ? intl.get('Customers.razao_social') : intl.get('Customers.cnome')
                  }
                />
              </label>

              <label className="split2">
                <span>{intl.get('Register.inscricao_estadual')}</span> <span className="required"> *</span>
                <Input
                  required
                  onChange={this.inputChangeHandlerCustomer}
                  value={oCustomer.cust_cIE || ''}
                  type="text"
                  name="cust_cIE"
                  id="cust_cIE"
                  placeholder={intl.get('Register.inscricao_estadual')}
                />
              </label>
              <label className="split2">
                {intl.get('BillingData.city_registration')}
                <input
                  onChange={this.inputChangeHandlerCustomer}
                  value={oCustomer.cust_cIM || ''}
                  type="text"
                  name="cust_cIM"
                  id="cust_cIM"
                  placeholder={intl.get('BillingData.city_registration')}
                />
              </label>
              <label className="split2">
                {intl.get('Customers.eenquadramentofiscal')} <span className="required"> *</span>
                <Input
                  type="select"
                  name="cust_etaxframework"
                  id="cust_etaxframework"
                  required
                  onChange={this.inputChangeHandlerCustomer}
                  value={oCustomer.cust_etaxframework}
                  defaultValue=""
                >
                  <option value="">{intl.get('select')}</option>
                  <option value="Optante">{intl.get('Customers.ef_optante')}</option>
                  <option value="Não optante">{intl.get('Customers.ef_nao_optante')}</option>
                  <option value="Isento">{intl.get('Customers.ef_isento')}</option>
                </Input>
              </label>

              <label className="split2">
                {intl.get('Customers.ctelefone')} <span className="required"> *</span>
                <MaskedInput
                  mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/]}
                  onChange={this.inputChangeHandlerCustomer}
                  render={(cRef, oProps) => (
                    <Input
                      required
                      innerRef={cRef}
                      {...oProps} // precisava analisar quais props são usados
                      value={oCustomer.cust_cphone || ''}
                      type="text"
                      name="cust_cphone"
                      id="cust_cphone"
                      className="mask-phone"
                      placeholder={intl.get('Customers.ctelefone')}
                    />
                  )}
                />
              </label>
            </>
          ) : (
            <>
              <label className="split2">
                <span>{intl.get('Customers.cnome')}</span>
                <Input
                  disabled
                  value={oUser.user_cname || ''}
                  type="text"
                  name="user_cname"
                  id="user_cname"
                  placeholder={intl.get('Customers.cnome')}
                />
              </label>

              <label className="split2">
                {intl.get('Customers.ccpf')} <span className="required"> *</span>
                <MaskedInput
                  onBlur={this.validateCPF}
                  // disabled={cliente.ccpf ? true : false}
                  mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
                  onChange={this.inputChangeHandlerCustomer}
                  render={(cRef, oProps) => (
                    <Input
                      innerRef={cRef}
                      {...oProps} // precisava analisar quais props são usados
                      value={oCustomer.cust_ccpf || ''}
                      type="text"
                      name="cust_ccpf"
                      id="cust_ccpf"
                      className="mask-cpf"
                      placeholder="CPF"
                      required={oCustomer.cust_ctype === 'PF'}
                    />
                  )}
                />
              </label>
            </>
          )}
        </fieldset>
      </>
    );
  };

  render() {
    const {
      bDatabaseStart,
      oUser,
      oPlan,
      rcmpAlert,
      oCustomer,
      bLoadingPostalCode,
      rcmpAlertFlash,
      bLoad,
      cToken,
      bIsSend,
      bValidPassword
    } = this.state;
    const cPasswordClassName = bValidPassword ? 'span-strong' : 'span-weak';
    const cPasswordIntlName = bValidPassword ? 'Customers.password_strong' : 'Customers.password_weak';

    return (
      <section className="containerRegister">
        {rcmpAlert}
        <img src={imgfLogo} alt="" />

        {bDatabaseStart === false ? (
          <div className="registerForm">
            <PageClean loading={bLoad ? 1 : 0}>
              {rcmpAlertFlash}
              <form autoComplete="off" onSubmit={this.handleSubmit} className="form" action="">
                <h1>{intl.get('Login.cadastro')}</h1>
                <br />
                <br />

                <fieldset>
                  <legend>{intl.get('usuario')}</legend>

                  <label className="split">
                    {intl.get('User.cidioma')}
                    <Input
                      type="select"
                      name="cust_clanguage"
                      id="cust_clanguage"
                      onChange={this.inputChangeHandlerCustomer}
                      value={oCustomer.cust_clanguage || 'pt-br'}
                    >
                      <option value="pt-br">{intl.get('User.cidioma_pt-br')}</option>
                      <option value="en">{intl.get('User.cidioma_en')}</option>
                    </Input>
                  </label>

                  <label className="split2">
                    {intl.get('User.cnome')} <span className="required"> *</span>
                    <Input
                      type="text"
                      name="user_cname"
                      id="user_cname"
                      maxLength="250"
                      required
                      onChange={this.inputChangeHandlerUser}
                      value={oUser.user_cname || ''}
                    />
                  </label>

                  <label className="split2">
                    {intl.get('cemail')}
                    <span className="required"> *</span>
                    <Input
                      type="text"
                      name="user_cemail"
                      id="user_cemail"
                      disabled
                      className="disabled"
                      maxLength="250"
                      required
                      value={oUser.user_cemail || ''}
                    />
                  </label>

                  <label className="split2 password">
                    {intl.get('csenha')} <span className="required"> *</span>
                    <InputPassword
                      name="user_cpassword"
                      id="user_cpassword"
                      data-lpignore="true"
                      required
                      onChange={this.inputChangeHandlerUser}
                      onKeyUp={this.validatePassword}
                      value={oUser.user_cpassword || ''}
                      autoComplete="off"
                    />
                    <div className="input-feedback input-feedback-passwordStrength">
                      {intl.get('Customers.password_strength')}:{' '}
                      <span className={cPasswordClassName}>{intl.get(cPasswordIntlName)}</span>
                    </div>
                    <div className="input-feedback input-feedback-passwordInstructions">
                      {intl.get('Customers.strong_password')}
                    </div>
                  </label>
                  <label className="split2 password">
                    {intl.get('cconfirmasenha')} <span className="required"> *</span>
                    <InputPassword
                      name="user_cpasswordconfirm"
                      id="user_cpasswordconfirm"
                      required
                      onChange={this.inputChangeHandlerUser}
                      value={oUser.user_cpasswordconfirm || ''}
                      autoComplete="off"
                    />
                  </label>

                  <label className="split2">
                    {intl.get('Customers.ccelular')}
                    <span className="required"> *</span>
                    <MaskedInput
                      mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                      onChange={this.inputChangeHandlerUser}
                      render={(cRef, oProps) => (
                        <Input
                          innerRef={cRef}
                          {...oProps} // precisava analisar quais props são usados
                          value={oUser.user_ccellphone || ''}
                          type="text"
                          name="user_ccellphone"
                          id="user_ccellphone"
                          className="mask-phone"
                          required
                          placeholder={intl.get('Customers.ccelular')}
                        />
                      )}
                    />
                  </label>
                </fieldset>
                {this.renderDataWithPagination()}

                <fieldset>
                  <legend>{intl.get('company')}</legend>

                  <label className="split1">
                    {intl.get('Register.nome_empresa')}
                    <Input
                      type="text"
                      name="cust_cfantasyname"
                      id="cust_cfantasyname"
                      maxLength="250"
                      onChange={this.inputChangeHandlerCustomer}
                      value={oCustomer.cust_cfantasyname || ''}
                    />
                  </label>

                  <label className="split2">
                    {intl.get('Register.cargo')} <span className="required"> *</span>
                    <Input
                      type="select"
                      name="cust_crole"
                      id="cust_crole"
                      required
                      onChange={this.inputChangeHandlerCustomer}
                      value={oCustomer.cust_crole}
                      defaultValue=""
                    >
                      <option value="">{intl.get('select')}</option>
                      <option value="Diretor e/ou sócio">{intl.get('Register.cargo_diretor_socio')}</option>
                      <option value="Gerente">{intl.get('Register.cargo_gerente')}</option>
                      <option value="Gestor da qualidade">{intl.get('Register.cargo_gestor_da_qualidade')}</option>
                      <option value="Médico">{intl.get('Register.cargo_medico')}</option>
                      <option value="Enfermeiro">{intl.get('Register.cargo_enfermeiro')}</option>
                      <option value="Farmacêutico / Bioquímico">{intl.get('Register.cargo_farmaceutico')}</option>
                      <option value="Biomédico">{intl.get('Register.cargo_biomedico')}</option>
                      <option value="Auxiliar administrativo">
                        {intl.get('Register.cargo_auxiliar_administrativo')}
                      </option>
                      <option value="Atendente">{intl.get('Register.cargo_atendente')}</option>
                      <option value="Estudante">{intl.get('Register.cargo_estudante')}</option>
                      <option value="Outro">{intl.get('outro')}</option>
                    </Input>
                  </label>

                  <label className="split2">
                    {intl.get('Register.num_funcionarios')} <span className="required"> *</span>
                    <Input
                      type="select"
                      name="cust_ctotalemployees"
                      id="cust_ctotalemployees"
                      required
                      onChange={this.inputChangeHandlerCustomer}
                      value={oCustomer.cust_ctotalemployees}
                      defaultValue=""
                    >
                      <option value="">{intl.get('select')}</option>
                      <option value="1-10">1 {intl.get('a_comparativo')} 10</option>
                      <option value="11-50">11 {intl.get('a_comparativo')} 50</option>
                      <option value="51-100">51 {intl.get('a_comparativo')} 100</option>
                      <option value="101-500">101 {intl.get('a_comparativo')} 500</option>
                      <option value="500+">{intl.get('acima_de')} 500</option>
                    </Input>
                  </label>

                  <label className="split2">
                    {intl.get('Register.segmento')} <span className="required"> *</span>
                    <Input
                      type="select"
                      name="cust_csegment"
                      id="cust_csegment"
                      required
                      onChange={this.inputChangeHandlerCustomer}
                      value={oCustomer.cust_csegment}
                      defaultValue=""
                    >
                      <option value="">{intl.get('select')}</option>
                      <option value="Saúde">{intl.get('Register.segmento_saude')}</option>
                      <option value="Tecnologia">{intl.get('Register.segmento_tecnologia')}</option>
                      <option value="Educação">{intl.get('Register.segmento_educacao')}</option>
                      <option value="Indústria">{intl.get('Register.segmento_industria')}</option>
                      <option value="Construção civil">{intl.get('Register.segmento_construcao_civil')}</option>
                      <option value="Outro">{intl.get('outro')}</option>
                    </Input>
                  </label>
                </fieldset>

                <fieldset>
                  <legend>{intl.get('Address.legenda_endereco')}</legend>

                  <label className="split2 search">
                    {intl.get('Address.ccep')}
                    <span className="required">*</span>
                    <MaskedInput
                      mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
                      onChange={this.inputChangeHandlerCustomer}
                      onBlur={this.verifyCEP}
                      required
                      render={(cRef, oProps) => (
                        <Input
                          required
                          type="text"
                          name="cust_cpostalcode"
                          id="cust_cpostalcode"
                          className="mask-cep cep-fill"
                          placeholder={intl.get('Address.ccep')}
                          innerRef={cRef}
                          {...oProps} // precisava analisar quais props são usados
                          value={oCustomer.cust_cpostalcode || ''}
                        />
                      )}
                    />
                    <span
                      role="button"
                      tabIndex="0"
                      aria-labelledby="split2"
                      title={intl.get('Address.busca_cep')}
                      className="btn-hover"
                      onKeyPress={() => this.verifyCEP()}
                      onClick={() => this.verifyCEP()}
                    />
                  </label>

                  <label className="split2">
                    {intl.get('Address.clogradouro')}
                    <span className="required"> *</span>
                    <Input
                      type="text"
                      name="cust_cstreet"
                      id="cust_cstreet"
                      className={bLoadingPostalCode ? 'loading' : ''}
                      maxLength="250"
                      onChange={this.inputChangeHandlerCustomer}
                      value={oCustomer.cust_cstreet || ''}
                      required
                    />
                  </label>

                  <label className="split2">
                    {intl.get('Address.cnumero')}
                    <span className="required"> *</span>
                    <Input
                      type="text"
                      name="cust_cnumber"
                      id="cust_cnumber"
                      maxLength="250"
                      onChange={this.inputChangeHandlerCustomer}
                      value={oCustomer.cust_cnumber || ''}
                      required
                    />
                  </label>

                  <label className="split2">
                    {intl.get('Address.ccomplemento')}
                    <Input
                      type="text"
                      name="cust_ccomplement"
                      id="cust_ccomplement"
                      maxLength="250"
                      onChange={this.inputChangeHandlerCustomer}
                      value={oCustomer.cust_ccomplement || ''}
                    />
                  </label>

                  <label className="split2">
                    {intl.get('Address.cbairro')}
                    <span className="required"> *</span>
                    <Input
                      type="text"
                      name="cust_cdistrict"
                      id="cust_cdistrict"
                      className={bLoadingPostalCode ? 'loading' : ''}
                      maxLength="250"
                      onChange={this.inputChangeHandlerCustomer}
                      value={oCustomer.cust_cdistrict || ''}
                      required
                    />
                  </label>

                  <label className="split2">
                    {intl.get('Address.ccidade')}
                    <span className="required"> *</span>
                    <Input
                      type="text"
                      name="cust_ccity"
                      id="cust_ccity"
                      className={bLoadingPostalCode ? 'loading' : ''}
                      maxLength="250"
                      onChange={this.inputChangeHandlerCustomer}
                      value={oCustomer.cust_ccity || ''}
                      required
                    />
                  </label>

                  <label className="split2">
                    {intl.get('Address.cestado')}
                    <span className="required"> *</span>
                    <Input
                      type="text"
                      name="cust_cstate"
                      id="cust_cstate"
                      className={bLoadingPostalCode ? 'loading' : ''}
                      maxLength="250"
                      onChange={this.inputChangeHandlerCustomer}
                      value={oCustomer.cust_cstate || ''}
                      required
                    />
                    <Input
                      type="hidden"
                      name="cust_cinitialsState"
                      id="cust_cinitialsState"
                      className={bLoadingPostalCode ? 'loading' : ''}
                      maxLength="2"
                      onChange={this.inputChangeHandlerCustomer}
                      value={oCustomer.cust_cinitialsState || ''}
                      required
                    />
                  </label>

                  <label className="split2">
                    {intl.get('Address.cpais')}
                    <span className="required"> *</span>
                    <Input
                      type="text"
                      name="cust_ccountry"
                      id="cust_ccountry"
                      className={bLoadingPostalCode ? 'loading' : ''}
                      maxLength="250"
                      onChange={this.inputChangeHandlerCustomer}
                      value={oCustomer.cust_ccountry || ''}
                      required
                    />
                  </label>
                </fieldset>
                <div className="checkboxWrapper">
                  <label name="cust_bterm" htmlFor="cust_bterm">
                    <input
                      value={oCustomer.cust_bterm || ''}
                      name="cust_bterm"
                      onChange={this.inputChangeHandlerCustomer}
                      id="cust_bterm"
                      type="checkbox"
                    />
                    {intl.get('Register.li_e_concordo')}{' '}
                    <a onClick={evtClick => this.openTerm(evtClick)} href="#term">
                      {intl.get('Register.termos_de_uso')}
                    </a>
                  </label>
                </div>
                <div className="button-stack">
                  <Button className={bIsSend ? 'loading disabled' : ''} type="submit">
                    {intl.get('Login.cadastrar')}
                  </Button>
                </div>
              </form>
            </PageClean>
          </div>
        ) : (
          <DatabaseBeingCreated cToken={cToken} />
        )}
      </section>
    );
  }
}

export default Register;
